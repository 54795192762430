import React from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

const IndexPage = () => (
  <Layout>
    <Seo title="Jobs" />
    <section className="page">
      <h1 className="text_huge">Jobs</h1>
      <article>
        <h2 className="text_bigger">Junior Web Entwickler (m/w)</h2>
        <span>(38,5h/Woche, in Bregenz)</span>

        <h3 className="text_big">Dein Profil</h3>
        <ul>
          <li>
            Du hast eine abgeschlossene Berufsausbildung zum Informatiker (HTL
            oder Studium, ideal mit Fokus auf Software- bzw. Web-Entwicklung)
          </li>
          <li>
            Du hast fundiertes Know-how in diversen Frontend-Technologien
            (HTML/CSS/JavaScript)
          </li>
          <li>
            Du hast Know-how in PHP und Erfahrungen mit diesbezüglichen
            Frameworks (z.B.: Laravel)
          </li>
          <li>
            Du hast Kenntnisse in der Entwicklung von Datenbank-Modellen (z.B.:
            MariaDB)
          </li>
          <li>Du bist motiviert neue Techniken und Software kennenzulernen</li>
        </ul>
        <p>
          Nicht jeder kann alles, aber jeder kann alles lernen. Wenn Dein Profil
          nicht allen Anforderungen entspricht, aber Du extrem motiviert und
          leistungsbereit bist, wirst Du bei uns alles lernen, was Du momentan
          noch nicht drauf hast.
        </p>

        <h3 className="text_big">Deine Aufgaben</h3>
        <ul>
          <li>
            Entwicklung von responsive Frontends für Web-Applikationen
            (Schwerpunkte: TYPO3, WordPress und individuelle Web-Applikationen)
          </li>
          <li>
            Qualitätssicherung der entwickelten Systeme (Softwaretesting,
            Dokumentation)
          </li>
          <li>
            Verbindung von Design, Benutzerfreundlichkeit und Sicherheit in
            allen Benutzeroberflächen
          </li>
          <li>Kundenbetreuung und Support</li>
          <li>
            selbstständige und organisierte Arbeitsweise mit hohem
            Qualitätsanspruch
          </li>
        </ul>

        <h3 className="text_big">Unser Angebot</h3>
        <ul>
          <li>ein angenehmes und kollegiales Arbeitsklima</li>
          <li>ein kleines aber verlässliches Team</li>
          <li>
            ein Monatsbruttogehalt zwischen € 1.850,-- und € 3.000,-- je nach
            Qualifikation, Erfahrung und Engagement
          </li>
        </ul>
        <p>
          Überzeuge uns!
          <br />
          Wir freuen uns auf deine Bewerbung mit Lebenslauf per E-Mail an <a href="mailto:office@netengine.at">office@netengine.at</a>.
        </p>
      </article>
    </section>
  </Layout>
)

export default IndexPage
